import React from 'react';

import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../../components/Loading/Loading';
import LoggedArea from '../../components/LoggedArea/LoggedArea';

import menu from '../../data/enums/menu';

import numberFormat from '../../utils/numberFormat';

import {
  UserActions,
  PlaceActions,
  IndicatorActions,
} from '../../redux/actions';

import * as S from './styles';

const IndicatorsPage = () => {
  const {
    placesName,
    isLoading: placeIsLoading,
    errorMessage: placeErrorMessage,
  } = useSelector(({ place }) => place);

  const {
    usersLog, videosLog, isLoading, errorMessage,
  } = useSelector(
    ({ indicator }) => indicator,
  );

  const [endDate, setEndDate] = React.useState('');
  const [placeId, setPlaceId] = React.useState(null);
  const [startDate, setStartDate] = React.useState('');
  const [formattedPlaces, setFormattedPlaces] = React.useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(UserActions.setSelectedMenu(menu.INDICATORS));

    dispatch(PlaceActions.getPlacesName());
  }, []);

  React.useEffect(() => {
    if (!placeId) {
      dispatch(IndicatorActions.cleanIndicators());
    }
  }, []);

  const handleFormattedPlaces = React.useCallback(() => {
    const allPlaces = { id: 'all', name: 'Todos' };

    setFormattedPlaces([allPlaces, ...placesName]);
  }, [placesName]);

  React.useEffect(() => {
    handleFormattedPlaces();
  }, [handleFormattedPlaces]);

  React.useEffect(() => {
    if (placeErrorMessage) {
      toast.error(placeErrorMessage);
    }
  }, [placeErrorMessage]);

  React.useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);

  const onGetIndicators = () => {
    const data = {
      placeId,
      startDate: `${startDate} 00:00`,
      endDate: `${endDate} 23:59`,
    };

    dispatch(IndicatorActions.getIndicators(data));
  };

  React.useEffect(() => {
    if (startDate && endDate) {
      onGetIndicators();
    }
  }, [placeId, startDate, endDate]);

  const onRangePickerChange = (dates) => {
    if (dates) {
      const [firstDate, secondDate] = dates;

      setStartDate(format(firstDate._d, 'yyyy-MM-dd'));
      setEndDate(format(secondDate._d, 'yyyy-MM-dd'));
    } else {
      setStartDate('');
      setEndDate('');
    }
  };

  const beautifyAgeGroup = (ageGroup) => {
    switch (ageGroup) {
      case 'not-informed':
        return t('notInformed');
      case 'over-50':
        return t('over50');
      default:
        return `${ageGroup} ${t('years')}`;
    }
  };

  return (
    <LoggedArea>
      <S.Container>
        {placeIsLoading ? (
          <Loading />
        ) : (
          <S.Content>
            {formattedPlaces && formattedPlaces.length ? (
              <S.Header>
                <S.AntDSelect
                  showSearch
                  bordered={false}
                  optionFilterProp="children"
                  onChange={(value) => setPlaceId(value)}
                  placeholder={t('selectDesiredLocation')}
                  filterOption={(input, option) => option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                >
                  {formattedPlaces.map((place) => (
                    <S.AntDOption key={String(place.id)} value={place.id}>
                      {place.name}
                    </S.AntDOption>
                  ))}
                </S.AntDSelect>

                <S.AntDRangePicker
                  format="DD/MM/YYYY"
                  onChange={onRangePickerChange}
                />
              </S.Header>
            ) : null}

            {isLoading ? <Loading /> : null}

            {!isLoading && videosLog && videosLog.videoViews ? (
              <S.UsersWrapper>
                <S.VideosContainer>
                  <S.Title>{t('videos')}</S.Title>

                  <S.Label>
                    {`${t('generated')}: ${numberFormat(
                      videosLog.createdVideos,
                    )}`}
                  </S.Label>
                  <S.Label>
                    {`${t('views')}: ${numberFormat(videosLog.videoViews)}`}
                  </S.Label>
                  <S.Label>
                    {`${t('saved')}: ${numberFormat(videosLog.savedByUsers)}`}
                  </S.Label>
                  <S.Label>
                    {`${t('shares')}: ${numberFormat(videosLog.shares)}`}
                  </S.Label>
                  <S.Label>
                    {`${t('likes')}: ${numberFormat(videosLog.videolikes)}`}
                  </S.Label>
                  <S.Label>
                    {`${t('greatPlay')}: ${numberFormat(videosLog.greatPlays)}`}
                  </S.Label>
                  <S.Label>
                    {`${t('badPlay')}: ${numberFormat(videosLog.badPlays)}`}
                  </S.Label>
                </S.VideosContainer>

                <S.UsersContainer>
                  <S.Title>{t('users')}</S.Title>

                  {placeId === 'all' ? (
                    <>
                      <S.Label>
                        {`${t('created')}: ${numberFormat(
                          usersLog.createdUsers,
                        )}`}
                      </S.Label>
                      <S.Label>
                        {`${t('activePlural')}: ${numberFormat(
                          usersLog.activeUsers,
                        )}`}
                      </S.Label>
                      <S.Label>
                        {`${t('deleted')}: ${numberFormat(
                          usersLog.deletedUsers,
                        )}`}
                      </S.Label>
                      <S.Label>
                        {`${t('followedProfiles')}: ${numberFormat(
                          usersLog.followedProfiles,
                        )}`}
                      </S.Label>
                      <S.Label>
                        {`${t('profileViews')}: ${numberFormat(
                          usersLog.profileViews,
                        )}`}
                      </S.Label>
                    </>
                  ) : (
                    <S.Label>
                      {`${t('playingAtThisPlace')}: ${numberFormat(
                        usersLog.playWhere,
                      )}`}
                    </S.Label>
                  )}

                  <div style={{ marginTop: 16 }}>
                    <S.Label style={{ color: '#ffea36' }}>
                      {`${t('ageGroups')}:`}
                    </S.Label>

                    {usersLog.ageGroups.map((item) => (
                      <ul key={item.ageGroup} style={{ color: 'white' }}>
                        <li>{`${t('age')}: ${beautifyAgeGroup(item.ageGroup)}`}</li>
                        <li>{`${t('total')}: ${numberFormat(item.total)}`}</li>
                      </ul>
                    ))}
                  </div>
                </S.UsersContainer>

                <div>
                  <S.Label style={{ color: '#ffea36' }}>
                    {`${t('genders')}:`}
                  </S.Label>

                  {usersLog.genderGroups.map((item) => (
                    <ul key={item.gender} style={{ color: 'white' }}>
                      <li>{`${t('gender')}: ${t(item.gender)}`}</li>
                      <li>{`${t('total')}: ${numberFormat(item.total)}`}</li>
                    </ul>
                  ))}
                </div>
              </S.UsersWrapper>
            ) : null}
          </S.Content>
        )}
      </S.Container>
    </LoggedArea>
  );
};

export default IndicatorsPage;
